import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery, GeoFenceFilter } from '@app/core/http/api-query';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataSet } from '@app/shared/kendo/data-set';
import * as moment from 'moment';
import { Room } from '@app/hotels/rooms/room.model';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { Logger } from '@app/core';
import { EventsService } from '@app/events/events/events.service';
import { Events } from '@app/events/events/events.model';
import { BookingPropertiesType } from '@app/settings/types/booking-properties-type.enum';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

const log = new Logger('AllotmentFinderComponent');

@Component({
  selector: 'app-allotment-finder',
  templateUrl: './allotment-finder.component.html',
  styleUrls: ['./allotment-finder.component.scss'],
})
export class AllotmentFinderComponent extends KendoGridComponent implements OnInit {
  @Input() returnValue: string;
  @Input() eventId: number;
  @Input() additionalInfo: any;
  @Input() hotelName: string;

  hotelBookingsRoomsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent | DataSet = new DataSet();
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  form: FormGroup;
  LaravelModel = LaravelModelsEnum;

  formPatched = false;
  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;

  constructor(
    public modal: NgbActiveModal,
    private hotelBookingService: HotelBookingsService,
    private hotelBookingsFormService: HotelBookingsFormService,
    protected kendoGridService: KendoGridService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private eventsService: EventsService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    log.debug('additionalInfo', this.additionalInfo);
    log.debug('returnValue', this.returnValue);
    log.debug('eventId', this.eventId);
    log.debug('hotelName', this.hotelName);

    (this.state as DataSet).addFilter('booking.property_id', BookingPropertiesType.ADV);

    if (this.eventId) {
      (this.state as DataSet).addFilter('event_id', this.eventId);
    }

    if (this.hotelName) {
      (this.state as DataSet).addFilter('hotel.name', this.hotelName);
    }

    this.createForm();

    this.patchForm();

    this.loadData(this.state);
  }

  /**
   *  Зарежда данните от апи-то
   * @param state state
   * @param searchButton дали функцията се вика от бутона
   */
  loadData(state: DataStateChangeEvent = null, searchButton: boolean = false) {
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'booking.company',
        'booking.events',
        'booking.supplier',
        'booking.cancellation_terms',
        'booking.property',
        'booking.tags',
        'hotel.city.country',
        'type',
        'meal_plan',
        'nights.net_price_currency',
        'nights.price_currency',
        'hotel.venue'
      )
      .addFilter('full_available', true);

    if (this.eventId) {
      this.apiQuery.addFilter('event_id', this.eventId);
    }

    if (searchButton) {
      // Проверява дали избрания във филтъра(допълнителния) event има закачено venue
      if (this.form.get('event').value && !this.form.get('event').value.venue) {
        return this.translate
          .get('HOTELS_BOOKINGS.HOTEL_BOOKINGS_ROOMS_LIST.EVENT_VENUE_NOT_FOUND')
          .subscribe((trans: string) => {
            this.toastr.warning(trans + this.form.get('event').value.name);
          });
      }

      // Проверява дали избрания във филтъра(допълнителния) event има закачено venue и дали то има координати
      if (
        this.form.get('event').value &&
        this.form.get('event').value.venue &&
        (!this.form.get('event').value.venue.lat || !this.form.get('event').value.venue.long)
      ) {
        return this.translate
          .get('HOTELS_BOOKINGS.HOTEL_BOOKINGS_ROOMS_LIST.EVENT_VENUE_LAT_LONG_NOT_FOUND')
          .subscribe((trans: string) => {
            this.toastr.warning(trans + this.form.get('event').value.venue.name);
          });
      }

      // Ако има валиден филтър закача към query-то geofence и датите от евента
      if (
        this.form.get('radius').value &&
        this.form.get('event').value &&
        this.form.get('event').value.venue &&
        this.form.get('event').value.venue.lat &&
        this.form.get('event').value.venue.long
      ) {
        const geoFenceFilter = new GeoFenceFilter();
        geoFenceFilter.lat = Number(this.form.get('event').value.venue.lat);
        geoFenceFilter.long = Number(this.form.get('event').value.venue.long);
        geoFenceFilter.inner_radius = 0;
        geoFenceFilter.outer_radius = Number(this.form.get('radius').value);
        this.apiQuery.setGeoFence(geoFenceFilter, 'hotel.geofence');

        // добавя датите от евента към филтрите в таблицата
        this.state = new DataSet()
          .deserialize(this.state)

          .addFilter(
            'check_in',
            moment(this.form.get('event').value.date_from)
              .subtract(this.form.get('additional_days').value || 0, 'days')
              .toDate(),
            'gte'
          )

          .addFilter(
            'check_out',
            moment(this.form.get('event').value.date_to)
              .add(this.form.get('additional_days').value || 0, 'days')
              .toDate(),
            'lte'
          );

        this.apiQuery.setDataSetFilters(this.state);
      }
    }

    this.hotelBookingService.indexRooms(this.apiQuery).subscribe((response: PaginatedResponse) => {
      if (this.form.get('event').value) {
        response.data.forEach((room: Room) => {
          // room.hotel.venues.forEach((venue: any) => {
          //   if (venue.id === this.form.get('event').value.venue_id) {
          //     room['distance_to_fair'] = venue.pivot.distance;
          //   }
          // });
        });
      }

      this.hotelBookingsRoomsResponse = response;
      this.data = response.getGridData();
      this.perfectScroll.update();
    });
  }

  /**
   * Използва се, за да премахва geofence когато някое от полетата form.event или form.radius е празно.
   * Вика се на change на някое от двете полета
   */
  eventFilterChange() {
    if (!this.form.get('event').value || !this.form.get('radius').value) {
      this.apiQuery.removeGeoFence('hotel.geofence').setPage(1);
      this.loadData(this.state);
    }
  }

  clearFilters() {
    this.form.get('event').patchValue(null);
    this.form.get('event_id').patchValue(null);
    this.form.get('radius').patchValue(null);
    this.state = null;
    this.eventFilterChange();
  }

  add() {
    this.hotelBookingsFormService.open().then(
      (response) => {
        this.loadData(this.state);
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  edit(hotelBooking: HotelBooking) {
    this.hotelBookingsFormService.open(hotelBooking).then(
      (response) => {
        this.loadData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  select(hotelBookingRoom: Room) {
    log.debug('hotelBookingRoom', this.returnValue, hotelBookingRoom[this.returnValue]);
    this.modal.close(hotelBookingRoom[this.returnValue]);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      event_id: [null],
      event: [null],
      radius: [null],
      additional_days: [3],
    });
  }

  private patchForm() {
    if (this.eventId) {
      const query = new ApiQuery().addInclude('venue');
      this.eventsService.show(this.eventId, query).subscribe((ev: Events) => {
        this.form.get('event_id').patchValue(ev.id);
        this.form.get('event').patchValue(ev);
        CustomKendoFilter.pushObjectInStorage(ev, 'events');
        this.formPatched = true;
      });
    }
  }
}
